import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    collection:[],
    loader:false,
    error: '',
    status:'idle',  
}


export const fetchOrders = createAsyncThunk('orders/list', async (id) => {

  let response;
  if(id){
    response = await axios.get(`https://impex-1becf-default-rtdb.firebaseio.com/orders.json?orderBy="$key"&startAt="${id}"&limitToFirst=20`);
  }else{
    response = await axios.get('https://impex-1becf-default-rtdb.firebaseio.com/orders.json');
  }
       
      let collection = response.data;
      const allData = []
      for (const iterator of Object.keys(collection)) {
         collection[iterator].id = iterator   
         allData.push(collection[iterator])          
      }            
      return allData
    }
  )

const slice = createSlice({
    name:'ordersList',
    initialState,
    extraReducers: (provider)=> {
        provider.addCase(fetchOrders.pending, (state)=> {        
          state.status ='Fetching...';  
          state.loader = true        
        })
        provider.addCase(fetchOrders.fulfilled, (state, {payload})=> {     
          state.collection =[]   
          state.collection = payload.splice(0 , 19);          
          state.status ='Succeeded';  
          state.loader = false               
         
        })
        provider.addCase(fetchOrders.rejected, (state)=> {        
          state.status ='Oops Something went wrong..';  
          state.loader = false        
        })
    }
})

export default slice.reducer; 