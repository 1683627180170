import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
function Dashboard() {
  return (
    <>
      <div className="overflow-auto p-4">
        <div className="d-flex justify-content-between ">
          <h2 className="fz18 text-blue fw600 mb-0">Dashboard</h2>
          <div className="ms-auto">
            <span className="fz16 align-middle fw600 text-light-grey me-3">Created Date:</span>
            <Dropdown className="custom-dessign-dropdown text-end text-ms-start d-inline-block outline-0">
              <Dropdown.Toggle type="button" className="btn-gradient btn py-1 fz16 shadow-none  text-light-grey mw150" variant="">
                Select Date
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end w-auto">
                <ul>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Today
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      This Week
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      This Month
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Last Month
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      This Year
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Last Year
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Custom
                    </Link>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row justify-content-center mt-4 dashboardTabs">
          <div className="col-xl-11">
            <div className="row g-xl-5">
              <div className="col-md-6 pb-xl-4 pe-xl-5">
                <div className="chart-box h-100 shadow p-4">
                  <div className="d-flex align-items-center flex-wrap">
                    <h4 className="fz18 fw600">Shipments by Status</h4>
                    <div className="btn-group  ms-auto">
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz14 shadow-none  text-light-grey mw150">Column Chart</Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Column Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Doughnut and Pie Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Bubble Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Area Chart
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz16 shadow-none border dropdown-icon-none px-3" variant="">
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-paper-plane"></i> Export Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download PNG
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download JPEG
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="chart-img mt-5 pt-xl-5">
                    <figure>
                      <img src="/assets/img/shipment-status-chart.jpg" className="img-fluid w-100" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-xl-4 ps-xl-5">
                <div className="chart-box h-100 shadow p-4">
                  <div className="d-flex align-items-center flex-wrap">
                    <h4 className="fz18 fw600">Sales Order by Status</h4>
                    <div className="btn-group  ms-auto">
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz14 shadow-none  text-light-grey mw150">Column Chart</Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Column Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Doughnut and Pie Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Bubble Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Area Chart
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz16 shadow-none border dropdown-icon-none px-3" variant="">
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-paper-plane"></i> Export Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download PNG
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download JPEG
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="chart-img mt-5 pt-xl-5">
                    <figure>
                      <img src="/assets/img/sales-order-status.jpg" className="img-fluid w-100" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-xl-4 pe-xl-5">
                <div className="chart-box h-100 shadow p-4">
                  <div className="d-flex align-items-center flex-wrap">
                    <h4 className="fz18 fw600 me-3">Top Items</h4>
                    <Dropdown>
                      <Dropdown.Toggle className="btn-gradient btn py-1 fz14 shadow-none  text-light-grey mw150">Top 1</Dropdown.Toggle>
                      <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                        <ul>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Top 1
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Top 2
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Top 3
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Top 4
                            </Link>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="btn-group ms-auto">
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz14 shadow-none  text-light-grey mw150">Column Chart</Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Column Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Doughnut and Pie Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Bubble Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Area Chart
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz16 shadow-none border dropdown-icon-none px-3" variant="">
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-paper-plane"></i> Export Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download PNG
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download JPEG
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="chart-img mt-5 pt-xl-5">
                    <figure>
                      <img src="/assets/img/top-item-chart.jpg" className="img-fluid w-100" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pb-xl-4 ps-xl-5">
                <div className="chart-box h-100 shadow p-4">
                  <div className="d-flex align-items-center flex-wrap">
                    <h4 className="fz18 fw600">Shipments based on vessel status</h4>
                    <div className="btn-group  ms-auto">
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz14 shadow-none  text-light-grey mw150">Column Chart</Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Column Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Doughnut and Pie Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Bubble Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Area Chart
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle className="btn-gradient btn py-1 fz16 shadow-none border dropdown-icon-none px-3" variant="">
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="" align="end" className="dropdown-menu dropdown-menu-end w-auto">
                          <ul>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-paper-plane"></i> Export Chart
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download PNG
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                <i className="fa-light me-2 fa-download"></i> Download JPEG
                              </Link>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="chart-img mt-5 pt-xl-5">
                    <figure>
                      <img src="/assets/img/vessele-chart.jpg" className="img-fluid w-100" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
