import { Link, NavLink } from "react-router-dom";
import Logo from "../../../assets/img/logo.png";
import Logo_icon from "../../../assets/img/logo-icon.png";
import {SidebarToggleAction} from '../../../store/slice/sidebarToggleSlice';
import {ThemeToggleAction} from '../../../store/slice/themeToggleSlice';
import {useDispatch,useSelector} from 'react-redux'
import { useEffect, useState } from "react";

function Sidebar() {

  const dispatch = useDispatch();
  const selector = useSelector(state => state)
  const [isMobile, setIsmobile] = useState(false)


  function changeTheme(val) {   
    val ?  localStorage.setItem("changeTheme", 1): localStorage.removeItem("changeTheme");   
    dispatch(ThemeToggleAction(val))
  }

  function toggleSideBar(val) {   
    val ?  localStorage.setItem("toggleNav", 1): localStorage.removeItem("toggleNav");   
    dispatch(SidebarToggleAction(val))
  }
  
  function outsideCloseSidebar(e) {     
    if(e.target.parentElement.classList.contains('nav-sidebar')){
        localStorage.removeItem("toggleNav");   
        dispatch(SidebarToggleAction(0))
    }   
  }

  useEffect(() => {
    window.innerWidth > 1200? setIsmobile(false) : setIsmobile(true)
  }, [])
  

  function MobileCloseSidebar(e) {     
    if(isMobile){
      localStorage.removeItem("toggleNav");   
      dispatch(SidebarToggleAction(0))
    }
   
  }

  return (
    <>
      <aside id="asidebar" onClick={outsideCloseSidebar}>
        <div className="position-relative">
          <div className="logo py-sm-2 px-3">
            <Link to="dashboard">
              <img src={Logo} className="lg-logo" alt="" />
              <img src={Logo_icon} className="sm-logo" alt="" />
            </Link>
            <button className="d-xl-none btn fz24" onClick={()=>toggleSideBar(!selector.sidebarToggle)}>
              <i className="fa-regular fa-times"></i>
            </button>
          </div>

          <button onClick={()=>toggleSideBar(!selector.sidebarToggle)} className="hamburger d-none d-xl-block"><i className="fas fa-angle-left"></i></button>
        </div>
        <div className="nav-sidebar d-flex flex-column">
          <nav className="flex-grow-1">
            <ul className="d-flex flex-column h-100">
              <li>
                <NavLink onClick={MobileCloseSidebar} to="dashboard" className={({ isActive }) => (isActive ? "active" : "")}>
                  <i className="fa-light fa-gauge-high"></i> <span> Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="orders" className={({ isActive }) => (isActive ? "active" : "")}>
                  <i className="fa-light fa-file-invoice"></i> <span> Orders</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="shipment" className={({ isActive }) => (isActive ? "active" : "")}>
                  <i className="fa-light fa-ship"></i> <span> Shipment</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="containers" className={({ isActive }) => (isActive ? "active" : "")}>
                  <i className="fa-light fa-container-storage"></i> <span> Containers</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="documents" className={({ isActive }) => (isActive ? "active" : "")}>
                  <i className="fa-light fa-file-contract"></i> <span> Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="couriers">
                  <i className="fa-light fa-truck-fast"></i> <span> Couriers </span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="products">
                  <i className="fa-light fa-cubes"></i> <span> Products </span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="offer">
                  <i className="fa-light fa-badge-dollar"></i>
                  <span> Offers</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="report">
                  <i className="fa-light fa-file-chart-column"></i> <span> Report</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="partners">
                  <i className="fa-light fa-user-circle"></i> <span> Partners</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="contacts">
                  <i className="fa-light fa-address-book"></i> <span> Contacts</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={MobileCloseSidebar} to="chat">
                  <i className="fa-light fa-comment-dots"></i> <span> Chat</span>
                </NavLink>
              </li>
              <li className="mt-auto border-top">
                <button onClick={()=>changeTheme(!selector.themeToggle)} className="change-theme bg-transparent border-0" title="Change Theme">
                  <i className={selector.themeToggle ? "fa-light fa-moon" : "fa-light fa-brightness"}></i> <span>Change Theme</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
