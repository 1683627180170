import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";

import store from "./store/store";
import { Provider } from "react-redux";

import Login from "./auth/Login";
import Dashboard from "./main/views/Dashboard";
import Layout from "./main/views/Layout";
import CommonLayout from "./main/views/components/Layout";
import Loader from "./main/views/components/loader/Loader";
import PageNotFound from "./main/PageNotFound";

function App() {
  const Orders = lazy(() => import("./main/views/orders/Orders"));
  const AddOrder = lazy(() => import("./main/views/orders/AddOrder"));
  const EditOrder = lazy(() => import("./main/views/orders/EditOrder"));
  const OrderBoard = lazy(() => import("./main/views/orders/OrderBoardEdit"));
  const Details = lazy(() => import("./main/views/orders/DetailPage"));

  const Containers = lazy(() => import("./main/views/containers/Containers"));
  const Contacts = lazy(() => import("./main/views/contacts/Contacts"));

  const Shipment = lazy(() => import("./main/views/shipment/Shipment"));
  const ShipmentBorad = lazy(() => import("./main/views/shipment/shipmentBorad"));
  const ShipmentBoradEdit = lazy(() => import("./main/views/shipment/ShipmentBoardEdit"));
  const DetailPage = lazy(() => import("./main/views/shipment/DetailPage"));
  const CommunicationDetails = lazy(() => import("./main/views/shipment/CommunicationDetails"));

  const Documents = lazy(() => import("./main/views/documents/Documents"));
  const Couriers = lazy(() => import("./main/views/couriers/Couriers"));
  const Chat = lazy(() => import("./main/views/chat/Chat"));

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader show={true} />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Order Routes Goes Here */}
            <Route path="/orders" element={<CommonLayout />}>
              <Route index element={<Orders />} />
              <Route path="add" element={<AddOrder />} />
              <Route path="board" element={<OrderBoard />} />
              <Route path="details/:id" element={<Details />} />
              <Route path="edit/:id" element={<EditOrder />} />
              <Route path="detail/communication-detail" element={<CommunicationDetails />} />
            </Route>

            {/* shipment Routes Goes Here */}
            <Route path="/shipment" element={<CommonLayout />}>
              <Route index element={<Shipment />} />
              <Route path=":detail" element={<DetailPage />} />
              <Route path="shipment-board" element={<ShipmentBorad />} />
              <Route path="shipment-board-edit" element={<ShipmentBoradEdit />} />
              <Route path="detail/communication-detail" element={<CommunicationDetails />} />
            </Route>

            {/* containers Routes Goes Here */}
            <Route path="/containers" element={<CommonLayout />}>
              <Route index element={<Containers />} />
            </Route>

            {/* documents Routes Goes Here */}
            <Route path="/documents" element={<CommonLayout />}>
              <Route index element={<Documents />} />
            </Route>

            {/* couriers Routes Goes Here */}
            <Route path="/couriers" element={<CommonLayout />}>
              <Route index element={<Couriers />} />
            </Route>

            {/* contacts Routes Goes Here */}
            <Route path="/contacts" element={<CommonLayout />}>
              <Route index element={<Contacts />} />
            </Route>

            {/* chat Routes Goes Here */}
            <Route path="/chat" element={<CommonLayout />}>
              <Route index element={<Chat />} />
            </Route>

            {/* PageNotFound Routes Goes Here */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </Provider>
  );
}

export default App;
