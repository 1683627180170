import React from "react";
const PageNotFound = () => {
  return (
    <>
      <div className="py-xl-4 px-3 order-form-fields">
        <h3 className="fz16 mt-5 text-center">Page Not Found</h3>
      </div>
    </>
  );
};

export default PageNotFound;
