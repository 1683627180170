import {createSlice} from '@reduxjs/toolkit';
const init = localStorage.getItem("toggleNav");
const toggleSlice =  createSlice({
    initialState:init ? 1 : 0,
    name:'sidebarToggle',
    reducers: {
        SidebarToggleAction: (state, {payload})=> {                                           
            return state = payload;   
                                 
        }
    }
});

export const {SidebarToggleAction} = toggleSlice.actions;
export default toggleSlice.reducer;