import { Outlet } from "react-router-dom";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import Footer from "./includes/Footer";
import { useSelector } from "react-redux";


function Layout() {
  const selector = useSelector(state => state);

  return (
    <>
      <div className={`template-default ${ selector.sidebarToggle ? 'menu-closed' : ''} ${ selector.themeToggle ? 'night-mode' : ''}`} id="template-default-page">
        <Sidebar />
        <div className="header-body-content">
          <Header />
          <Outlet />         
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
