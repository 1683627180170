import { Link } from "react-router-dom";

import LoginImage from "../assets/img/login-left-img.png";

function Login() {
  return (
    <>
      <main className="login-page-bg d-flex flex-column  justify-content-between">
        <div className="login-box bg-white p-lg-5 p-4 rounded">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <figure className="d-none d-lg-block text-center">
                <img src={LoginImage} className="img-fluid" alt="" />
              </figure>
            </div>
            <div className="col-lg-6">
              <div className="login-wrap">
                <figure className="d-block mb-4 text-centr">
                  <img src="assets/img/logim-impex-logo.png" className="img-fluid" alt="" />
                </figure>
                <h3 className="fz28 fw800  mb-5">Customer Login Portal</h3>
                <form action="">
                  <div className=" mb-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text bg-transparent px-4 fz18">
                        <i className="fa-light fa-envelope"></i>
                      </span>
                      <input type="text" className="shadow-none rounded border-start-0 form-control fz18 px-0" placeholder="Email Address" />
                    </div>
                  </div>
                  <div className=" mb-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text bg-transparent px-4 fz18">
                        <i className="fa-light fa-key"></i>
                      </span>
                      <input type="text" className="shadow-none border-start-0 rounded form-control fz18 px-0 " placeholder="Password" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <div className="form-check ps-0 d-flex align-items-center form-check-inline">
                        <input className="form-check-input ms-0 rounded-0 mt-0" type="checkbox" id="inlineCheckbox1" />
                        <label className="fz14 form-check-label ms-2 " htmlFor="inlineCheckbox1">
                          Remember Password?
                        </label>
                      </div>
                    </div>
                    <div className="col-5 text-end">
                      <Link to="" className="text-blue fz14 d-inline-block">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  {/* <button className="btn bg-green text-white fz30 w-100 fw800">Login</button> */}
                  <Link to="dashboard" className="bg-green btn fz14 fz18 mt-3 mt-4 py-1 text-white w-100">
                    LOGIN
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="login-footer border-top">
          <div className="container">
            <div className="px-lg-5 px-3">
              <p className="fz14 text-center">
                <span className="fw700">Note: </span> ImpexDocs will not be responsible for any loss or damage caused by the use of this software. See below for our detailed
                <Link to="" className="fw700">
                  Terms Of Service.
                </Link>
                By login in to the software the user is deemed to have accepted our Terms Of Service.
              </p>
            </div>
            <div className="login-footer-div px-lg-5  px-3">
              <div className="row align-items-center">
                <div className=" col-sm-6 ">
                  <p className="fz14 text-center text-lg-start mb-0">Copyright 2021 ImpexDocs All Rights Reserved</p>
                </div>
                <div className="  col-sm-6 my-2 my-lg-0">
                  <ul className="d-flex justify-content-end">
                    <li>
                      <Link to="" className="fz14 me-2">
                        Terms Of Service &nbsp; |
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="fz14 me-2">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="fz14">
                        | &nbsp;Support
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Login;
