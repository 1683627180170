import {createSlice} from '@reduxjs/toolkit';
const init = localStorage.getItem("changeTheme");
const toggleSlice =  createSlice({
    initialState:init ? 1 : 0,
    name:'themeToggle',
    reducers: {
        ThemeToggleAction: (state, {payload})=> {                                           
            return state = payload;   
                                 
        }
    }
});

export const {ThemeToggleAction} = toggleSlice.actions;
export default toggleSlice.reducer;