import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="main-footer shadow">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <p className="fz14 mb-0 ">© Impex Docs</p>
            <ul className="d-flex align-items-center">
              <li className="fz14 text-dark  me-3">Need Help</li>
              <li className="me-2">
                <Link to="" className=" fz16 text-dark  ">
                  <i className="fa-light fa-headphones"></i>
                </Link>
              </li>
              <li>
                <Link to="" className=" fz16 text-dark ">
                  <i className="fa-light fa-circle-question"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
