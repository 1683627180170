import axios from "axios";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo-icon.png";
import { SidebarToggleAction } from "../../../store/slice/sidebarToggleSlice";
function Header() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.sidebarToggle);

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("https://jsonplaceholder.typicode.com/users");
      setAllUsers(data);
    })();
  }, []);

  function toggleSideBar(val) {
    val ? localStorage.setItem("toggleNav", 1) : localStorage.removeItem("toggleNav");
    dispatch(SidebarToggleAction(val));
  }

  return (
    <>
      <header className="top-header">
        <div className="d-flex justify-content-between gap-3 align-items-center">
          <div className="d-flex align-items-center me-auto">
            <div onClick={() => toggleSideBar(!selector)} className="fz22 d-xl-none me-3">
              <i className="fa-regular fa-bars-sort"></i>
            </div>
            <div className="sm-logo me-2 d-xl-none">
              <Link to="https://wireframe.impexdocs.com/">
                <img src={Logo} className="" alt="" />
              </Link>
            </div>
            <span className="fz16 fw600 d-none d-xl-block me-2">Exporter :</span>
            <div className="coles-supermarket">
              <Dropdown>
                <Dropdown.Toggle className="w-100 d-block btn  p-0 dropdown-toggle" variant="">
                  Coles Supermaket
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu position-fixed dropdown-with-search">
                  <div className="form-group px-2 border-bottom pb-2">
                    <div className="position-relative">
                      <input type="text" className="form-control fw600 shadow-none  rounded p-2" placeholder="Search" />
                      <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                        <i className="fa-light fa-search"></i>
                      </button>
                    </div>
                  </div>
                  <Link to="#/action-1" className="dropdown-item py-2">
                    
                    Kamakura Ham Murai Shokai Co Ltd
                  </Link>
                  <Link to="#/action-1" className="dropdown-item py-2">
                    
                    Huadong Food Suzhou Merlog Food
                  </Link>
                  <Link to="#/action-1" className="dropdown-item py-2">
                    
                    Kamakura Ham Murai Shokai Co Ltd{" "}
                  </Link>
                  <Link to="#/action-1" className="dropdown-item py-2">
                    {" "}
                    Huadong Food Suzhou Merlog{" "}
                  </Link>
                  <Link to="#/action-1" className="dropdown-item py-2">
                    {" "}
                    Murai Shokai Co Ltd{" "}
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <ul className="header-right-links d-flex align-items-center">
              <li>
                <Link to="support-new">
                  <i className="fa-light fa-circle-info"></i>
                </Link>
              </li>
              <li>
                <Link to="chat">
                  <i className="fa-light fa-comment-dots"></i>
                </Link>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle align="end" className="blurDropdownBg blurNoneXl p-0 dropdown-icon-none" variant="">
                    <Link className="blurDropdownBg blurNoneXl">
                      <i className="fa-light fa-bell"></i>
                      <span className="position-absolute top-0  translate-middle badge">9</span>
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu text-small shadow notification-header  dropdown-slide-right">
                    <div className="dropdown-header border-bottom d-flex align-items-center notification-name-head">
                      <span width="30" height="30" className="profile-icon rounded-circle me-3">
                        {" "}
                        <i className="fa-light fa-bell"></i>
                      </span>
                      <h5 className="fz18 mb-0"> New Notification </h5>
                      <span className="btn btn-danger rounded btn-sm ms-auto">{allUsers.length}</span>
                    </div>
                    <div className="dropdown-body">
                      <ul>
                        {allUsers &&
                          allUsers.map((ele, i) => (
                            <li key={i}>
                              <div className="d-flex">
                                <span className="notify-img me-3"> <img src={`https://i.pravatar.cc/40?img=${ele.name}`} alt="" /></span>
                                <div>
                                  <Link to="" className="fz14 text-dark ms-0 fw700"> {ele.name} </Link>
                                  <div className="desc fz14">{ele.company.catchPhrase}</div>
                                </div>
                              </div>
                              <div className="d-flex mt-2 justify-content-between">
                                <span className="fz14 text-black">02-02-2022</span> <div className="text-end fz14 text-black-50">10 Min Ago</div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="dropdown-footer text-center border-top py-3"> <Link className="text-black fz14" to="">  <i className="fa-light fa-eye me-1"></i> View All  </Link> </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="d-none d-md-inline-block">
                <Link to="">
                  
                  <i className="fa-light fa-gear"></i>
                </Link>
              </li>
              <li className="ms-4 ms-lg-5 ">
                <Dropdown className="flex-shrink-0 dropdown logoutDropdown">
                  <Dropdown.Toggle variant="" className="dropdown-icon-none p-0">
                    <Link to="#" className="d-block blurDropdownBg profileDropDownWrap link-dark fz14 ms-0">
                      <span width="30" height="30" className="profile-icon rounded-circle">
                        ak
                      </span>
                      <span className="d-none d-sm-inline"> Ashwani Kumar </span> <i className="fz14 d-none d-xl-inline ms-0 fa-light fa-angle-down"></i>
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="shadow dropdown-menu-md-start profileDropDown">
                    <div className="px-3 profile-name-head border-bottom d-xl-none">
                      <div className="d-flex link-dark fz14">
                        <span width="30" height="30" className="profile-icon rounded-circle">
                          ak
                        </span>
                        <div className="ms-2">
                          <h5 className="fz18 mb-0"> Ashwani Kumar</h5>
                          <span className="small">ashwani@broadwayinfotech.com</span>
                        </div>
                      </div>
                    </div>
                    <ul className=" text-small py-0">
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light fa-gear"></i> Settings
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light fa-key"></i> Change Password
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light fa-user"></i>User Profile
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider d-none d-xl-block" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          <i className="fa-light fa-right-from-bracket"></i> Sign out
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
