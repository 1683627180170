import { Outlet } from 'react-router-dom';
import '../../../assets/css/shipment.scss';

const Layout = () => {
  return (
    <div className="body-content flex-grow-1 d-flex flex-column  ">
      <Outlet />
    </div>
  )
}


export default Layout;