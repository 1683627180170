import {configureStore} from '@reduxjs/toolkit';
import sidebarToggle from './slice/sidebarToggleSlice'
import themeToggle from './slice/themeToggleSlice';
import orderSlice  from './slice/orderSlice';

export default configureStore({
    reducer: {
        sidebarToggle,
        themeToggle,
        orderSlice
    }
})